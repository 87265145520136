import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../config/routes";

import { 
  Dashboard, DashboardPersonalDetails,  BusinessDetails, 
  ContactDetails, ApprocalPending, ApprocalPlans,
  AgentDashboard, AgentLanguage, AgentPrompt, CallDashboard, CallTranscript,
  Login, ForgotPassword, GetOTP, Register, ResetPassword, AddBalance
} from "./RouterPage";

const Router: React.FC = (): JSX.Element  => {
  
  const userData = null;
  const initialRoute = () => {
    if(userData){ 
      return Login;
    } else {
      return Dashboard;
    }
  };

  return (
      <Suspense
        fallback={
          <div className="main-loading flex flex-col items-center justify-center w-full h-[100vh]">
            <p>Loading...</p>
          </div>
        }
      >
        <Routes>
          <Route path={routes.login} Component={Login} />
          <Route path={routes.forgotPassword} Component={ForgotPassword} />
          <Route path={routes.getOTP} Component={GetOTP} />
          <Route path={routes.register} Component={Register} />
          <Route path={routes.resetPassword} Component={ResetPassword} />
          <Route path={routes.addBalance} Component={AddBalance} />

          <Route path={routes.dashboardPersonalDetails} Component={DashboardPersonalDetails} />
          <Route path={routes.businessDetails} Component={BusinessDetails} />
          <Route path={routes.contactDetails} Component={ContactDetails} />
          <Route path={routes.dashboard} Component={Dashboard} />
          <Route path={routes.approcalPending} Component={ApprocalPending} />
          <Route path={routes.approcalPlans} Component={ApprocalPlans} />

          <Route path={routes.agentDashboard} Component={AgentDashboard} />
          <Route path={routes.agentLanguage} Component={AgentLanguage} />
          <Route path={routes.agentPrompt} Component={AgentPrompt} />
          
          <Route path={routes.callDashboard} Component={CallDashboard} />
          <Route path={routes.callTranscript} Component={CallTranscript} />
          
          <Route path="*" Component={initialRoute()} />
        </Routes>
      </Suspense>
  );
};

export default Router;