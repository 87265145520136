export const routes = {
  
  dashboardPersonalDetails: "/dashboard-personal-details",
  personalStatus: "/superadmin/personal-status",
  purchaseNumbers: "/superadmin/purchase-numbers",
  purchasedPhoneNumbers: "/superadmin/purchased-phone-numbers",
  userList: "/superadmin/user-list",
  dashboard: "/dashboard",

  login: "/customer/login",
  forgotPassword: "/customer/forgot-password",
  getOTP: "/customer/get-otp",
  register: "/customer/register",
  resetPassword: "/superadmin/reset-password"

};
