import React from "react";
import { useNavigate } from "react-router-dom";

export const UserList: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="o-dasboard__rightbar o-dasboard__rightbar--doctordash">
      <a href="index.html" className="o-dasboard__backbtn">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3867 12.2266H5.38672"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.3867 19.2266L5.38672 12.2266L12.3867 5.22656"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        BACK
      </a>
      <div className="o-dasboard__rightheading">
        <h2 className="o-dasboard__title">Purchase numbers</h2>
      </div>
      <div className="o-dasboard__rightbody o-dasboard__countwrapper flex-body">
        <div className="o-dasboard__purchaselist">
          <p className="o-dasboard__purtoptext">
            Select and purchase phone numbers for AI-powered automated calling
            services, including lead generation and customer support.
          </p>
          <div className="o-dasboard__listwrapper">
            <div className="o-dasboard__listfilter">
              <div className="input-groups">
                <label>Search by number</label>
                <input type="text" className="input-field" />
              </div>
              <div className="input-groups">
                <label>Search by Area</label>
                <input type="text" className="input-field" />
              </div>
              <div className="input-groups">
                <label>Search by Region</label>
                <select className="input-field input-field--select">
                  <option selected>United Kingdom</option>
                  <option>France</option>
                  <option>Germany</option>
                </select>
              </div>
            </div>
            <div className="o-dasboard__listitems">
              <ul className="list-title">
                <li>Number</li>
                <li>Region</li>
                <li>Action</li>
              </ul>
              <ul className="list-iems">
                <li>
                  <span>Number</span>
                  <img src="assets/images/icons/usa.png" alt="usa" /> 760 891
                  4080
                </li>
                <li>
                  <span>Region</span>San Marcos, CA, US
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-target="modaledit"
                    className="btn js-open-modal"
                  >
                    PURCHASE
                  </a>
                </li>
              </ul>
              <ul className="list-iems">
                <li>
                  <span>Number</span>
                  <img src="assets/images/icons/usa.png" alt="usa" /> 760 891
                  4080
                </li>
                <li>
                  <span>Region</span>San Marcos, CA, US
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-target="modaledit"
                    className="btn js-open-modal"
                  >
                    PURCHASE
                  </a>
                </li>
              </ul>
              <ul className="list-iems">
                <li>
                  <span>Number</span>
                  <img src="assets/images/icons/usa.png" alt="usa" /> 760 891
                  4080
                </li>
                <li>
                  <span>Region</span>San Marcos, CA, US
                </li>
                <li>
                  <a href="javascript:void(0)" className="btn">
                    PURCHASE
                  </a>
                </li>
              </ul>
              <ul className="list-iems">
                <li>
                  <span>Number</span>
                  <img src="assets/images/icons/usa.png" alt="usa" /> 760 891
                  4080
                </li>
                <li>
                  <span>Region</span>San Marcos, CA, US
                </li>
                <li>
                  <a href="javascript:void(0)" className="btn">
                    PURCHASE
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="o-dasboard__purchasenumber">
          <a href="javascript:void(0)" className="perchasecount">
            <img src="assets/images/count-purchase.svg" alt="count purchase" />
            <span className="countnumber">3</span>
            <p>Purchased numbers</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 12H19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 5L19 12L12 19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <img src="assets/images/count-phone.svg" alt="count phone" />
        </div>
      </div>
    </div>
  );
};
