export const agentData = [
  {
    voice_id: "11labs-Dorothy",
    voice_name: "Dorothy",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/Dorothy.mp3",
    response_voice_age_type: "Young",
    gender: "female",
    accent: "British",
  },
  {
    voice_id: "openai-Nova",
    voice_name: "Nova",
    voice_provider: "openai",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/nova_.wav",
    response_voice_age_type: "Old",
    gender: "female",
    accent: "American",
  },
  {
    voice_id: "11labs-Anthony",
    voice_name: "Anthony",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/anthony.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "British",
  },
  {
    voice_id: "11labs-Billy",
    voice_name: "Billy",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/billy.mp3",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Lily",
    voice_name: "Lily",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/lily.mp3",
    response_voice_age_type: "Young",
    gender: "female",
    accent: "American",
  },
  {
    voice_id: "11labs-Marissa",
    voice_name: "Marissa",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/marissa.mp3",
    response_voice_age_type: "Young",
    gender: "female",
    accent: "American",
  },
  {
    voice_id: "11labs-Amy",
    voice_name: "Amy(UK)",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/Amy.mp3",
    response_voice_age_type: "Young",
    gender: "female",
    accent: "British",
  },
  {
    voice_id: "11labs-Bing",
    voice_name: "Bing",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/bing.mp3",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Jenny",
    voice_name: "Jenny",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/Jenny.mp3",
    response_voice_age_type: "Young",
    gender: "female",
    accent: "American",
  },
  {
    voice_id: "11labs-Lucas",
    voice_name: "Lucas",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Samad",
    voice_name: "Samad (en-IN)",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/samad.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "Indian",
  },
  {
    voice_id: "11labs-Brian",
    voice_name: "Brian",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/brian.mp3",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Kate",
    voice_name: "Kate",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/kate.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "female",
    accent: "American",
  },
  {
    voice_id: "11labs-Jason",
    voice_name: "Jason",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/jason.mp3",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "openai-Echo",
    voice_name: "Echo",
    voice_provider: "openai",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/echo_.wav",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Ethan",
    voice_name: "Ethan",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/ethan.mp3",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "openai-Fable",
    voice_name: "Fable",
    voice_provider: "openai",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/fable_.wav",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "British",
  },
  {
    voice_id: "11labs-Paul",
    voice_name: "Paul",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/paul.mp3",
    response_voice_age_type: "Old",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-John",
    voice_name: "John",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/John.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "deepgram-Varun",
    voice_name: "Varun",
    voice_provider: "deepgram",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/varun.wav",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Joe",
    voice_name: "Joe",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/joe.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Adrian",
    voice_name: "Adrian",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/adrian.mp3",
    response_voice_age_type: "Young",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Gilfoy",
    voice_name: "Gilfoy",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/gilfoy.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Zuri",
    voice_name: "Zuri",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/zuri.mp3",
    response_voice_age_type: "Old",
    gender: "female",
    accent: "American",
  },
  {
    voice_id: "11labs-Noah",
    voice_name: "Noah (en-AU)",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/noah.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "male",
    accent: "Australian",
  },
  {
    voice_id: "11labs-Steve",
    voice_name: "Steve",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/Steve-.mp3",
    response_voice_age_type: "Old",
    gender: "male",
    accent: "American",
  },
  {
    voice_id: "11labs-Grace",
    voice_name: "Grace",
    voice_provider: "elevenlabs",
    preview_audio_url:
      "https://retell-utils-public.s3.us-west-2.amazonaws.com/grace.mp3",
    response_voice_age_type: "Middle Aged",
    gender: "female",
    accent: "American",
  },
];
