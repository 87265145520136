import React from "react";
import { useNavigate } from "react-router-dom";

export const Header : React.FC = (): JSX.Element  =>  {

  const navigate = useNavigate();

  return (
    <header className="o-header">
        <div className="o-container">
            <div className="o-header__container">
                <div className="o-header__left-column">
                    <a 
                            onClick={()=> 
                                navigate("/")
                            } 
                            className="o-header__logo o-cusror-pointer">
                        <img
                            src="assets/images/logo.svg"
                            alt="TCALL LOGO"
                            width="110"
                            height="36"
                        />
                    </a>
                </div>
                <div className="o-header__right-column">    
                    <div className="o-header__user">
                        <i><img src="assets/images/user.png" alt="user" /></i>
                        <a href="javascript:void(0)" className="o-header__userarrow">
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.5L6 6.5L11 1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <ul className="o-header__usermenu">
                            <li><a>profile</a></li>
                        </ul>
                    </div>
                    <a href="javascript:void(0)" className="o-dasboard__hamburg">
                        <img src="assets/images/icons/hamburg.svg" alt="menu icon" />
                    </a>
                </div>
            </div>
        </div>
    </header>
  )
}