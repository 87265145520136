import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../config/routesSuperAdmin";
import { UserList } from "../pages/superadmin/UserList";

import { 
  Dashboard, DashboardPersonalDetails, PersonalStatus,
  Login, ForgotPassword, GetOTP, Register, ResetPassword, PurchaseNumbers, PurchasedPhoneNumbers
} from "./RouterSuperAdminPage";

const SuperAdminRouter: React.FC = (): JSX.Element  => {
  
  const userData = null;
  const initialRoute = () => {
    if(userData){ 
      return Login;
    } else {
      return Dashboard;
    }
  };

  return (
      <Suspense
        fallback={
          <div className="main-loading flex flex-col items-center justify-center w-full h-[100vh]">
            <p>Loading...</p>
          </div>
        }
      >
        <Routes>
          <Route path={routes.login} Component={Login} />
          <Route path={routes.forgotPassword} Component={ForgotPassword} />
          <Route path={routes.getOTP} Component={GetOTP} />
          <Route path={routes.register} Component={Register} />
          <Route path={routes.resetPassword} Component={ResetPassword} />

          <Route path={routes.dashboardPersonalDetails} Component={DashboardPersonalDetails} />
          <Route path={routes.dashboard} Component={Dashboard} />
          <Route path={routes.personalStatus} Component={PersonalStatus} />
          <Route path={routes.purchaseNumbers} Component={PurchaseNumbers} />
          <Route path={routes.purchasedPhoneNumbers} Component={PurchasedPhoneNumbers} />
          <Route path={routes.userList} Component={UserList} />
          
          <Route path="*" Component={initialRoute()} />
        </Routes>
      </Suspense>
  );
};

export default SuperAdminRouter;