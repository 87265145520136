const prod = {
  ENVIRONMENT : "PROD",
  SERVER_BASE_URL : "https://dev.backend.tcall.ai/",
  WEBSOCKET_URL: ""
}

const dev = {
  ENVIRONMENT : "STAGE",
  SERVER_BASE_URL : "https://dev.backend.tcall.ai/",
  WEBSOCKET_URL: ""
}

const prodDomain = ['prod-tcall.com']
const isProd = prodDomain.includes(document.domain);

const config = isProd ? prod : dev;

export default {
  // Add common config values here
  MAX_CHARACTER_FILENAME: 30,
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};