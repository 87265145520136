import { lazy } from "react";

const Login = lazy(() => import("../pages/customers/Login").then(({Login}) => ({default: Login})));
const ForgotPassword = lazy(() => import("../pages/customers/ForgotPassword").then(({ForgotPassword}) => ({default: ForgotPassword})));
const GetOTP = lazy(() => import("../pages/customers/GetOTP").then(({GetOTP}) => ({default: GetOTP})));
const Register = lazy(() => import("../pages/customers/Register").then(({Register}) => ({default: Register})));
const ResetPassword = lazy(() => import("../pages/customers/ResetPassword").then(({ResetPassword}) => ({default: ResetPassword})));

const AddBalance = lazy(() => import("../pages/basic/AddBalance").then(({AddBalance}) => ({default: AddBalance})));

const Dashboard = lazy(() => import("../pages/basic/Dashboard").then(({Dashboard}) => ({default: Dashboard})));
const DashboardPersonalDetails = lazy(() => import("../pages/basic/DashboardPersonalDetails").then(({DashboardPersonalDetails}) => ({default: DashboardPersonalDetails})));
const BusinessDetails = lazy(() => import("../pages/basic/BusinessDetails").then(({BusinessDetails}) => ({default: BusinessDetails})));
const ContactDetails = lazy(() => import("../pages/basic/ContactDetails").then(({ContactDetails}) => ({default: ContactDetails})));
const ApprocalPending = lazy(() => import("../pages/basic/ApprocalPending").then(({ApprocalPending}) => ({default: ApprocalPending})));
const ApprocalPlans = lazy(() => import("../pages/basic/ApprocalPlans").then(({ApprocalPlans}) => ({default: ApprocalPlans})));

const AgentDashboard = lazy(() => import("../pages/agents/Dashboard").then(({Dashboard}) => ({default: Dashboard})));
const AgentLanguage = lazy(() => import("../pages/agents/AgentLanguage").then(({AgentLanguage}) => ({default: AgentLanguage})));
const AgentPrompt = lazy(() => import("../pages/agents/AgentPrompt").then(({AgentPrompt}) => ({default: AgentPrompt})));

const CallDashboard = lazy(() => import("../pages/calls/CallDashboard").then(({CallDashboard}) => ({default: CallDashboard})));
const CallTranscript = lazy(() => import("../pages/calls/CallTranscript").then(({CallTranscript}) => ({default: CallTranscript})));

export { 
  Dashboard, DashboardPersonalDetails, BusinessDetails, 
  ContactDetails, ApprocalPending, ApprocalPlans, 
  AgentDashboard, AgentLanguage, AgentPrompt, CallDashboard, CallTranscript,
  Login, ForgotPassword, GetOTP, Register, ResetPassword, AddBalance,
}