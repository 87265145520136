export const regions = {
  Estonia: "EE",
  Denmark: "DK",
  Bulgaria: "BG",
  Malaysia: "MY",
  Chile: "CL",
  Brazil: "BR",
  Thailand: "TH",
  Portugal: "PT",
  Italy: "IT",
  Romania: "RO",
  "United Kingdom": "GB",
  Argentina: "AR",
  Austria: "AT",
  Belgium: "BE",
  Botswana: "BW",
  Colombia: "CO",
  Croatia: "HR",
  Finland: "FI",
  Indonesia: "ID",
  Israel: "IL",
  Mali: "ML",
  Mexico: "MX",
  Peru: "PE",
  Philippines: "PH",
  Poland: "PL",
  Slovakia: "SK",
  "South Africa": "ZA",
  Sweden: "SE",
  Tunisia: "TN",
  Namibia: "NA",
  Guinea: "GN",
  Germany: "DE",
  Macao: "MO",
  Hungary: "HU",
  Ireland: "IE",
  Australia: "AU",
  Lithuania: "LT",
  Japan: "JP",
  Canada: "CA",
  Panama: "PA",
  "Czech Republic": "CZ",
  Ghana: "GH",
  Algeria: "DZ",
  "United States": "US",
  "Virgin Islands, U.S.": "VI",
  Ecuador: "EC",
  Kenya: "KE",
  Netherlands: "NL",
  Switzerland: "CH",
  Slovenia: "SI",
  "Puerto Rico": "PR",
  "New Zealand": "NZ",
  "Hong Kong": "HK",
  Grenada: "GD",
  Greece: "GR",
  Georgia: "GE",
  "El Salvador": "SV",
  "Bosnia and Herzegovina": "BA",
  Benin: "BJ",
  Barbados: "BB",
};
