import { lazy } from "react";

const Login = lazy(() => import("../pages/customers/Login").then(({Login}) => ({default: Login})));
const ForgotPassword = lazy(() => import("../pages/customers/ForgotPassword").then(({ForgotPassword}) => ({default: ForgotPassword})));
const GetOTP = lazy(() => import("../pages/customers/GetOTP").then(({GetOTP}) => ({default: GetOTP})));
const Register = lazy(() => import("../pages/customers/Register").then(({Register}) => ({default: Register})));
const ResetPassword = lazy(() => import("../pages/superadmin/ResetPassword").then(({ResetPassword}) => ({default: ResetPassword})));

const Dashboard = lazy(() => import("../pages/superadmin/Dashboard").then(({Dashboard}) => ({default: Dashboard})));
const DashboardPersonalDetails = lazy(() => import("../pages/superadmin/DashboardPersonalDetails").then(({DashboardPersonalDetails}) => ({default: DashboardPersonalDetails})));
const PersonalStatus = lazy(() => import("../pages/superadmin/PersonalStatus").then(({PersonalStatus}) => ({default: PersonalStatus})));
const PurchaseNumbers = lazy(() => import("../pages/superadmin/PurchaseNumbers").then(({PurchaseNumbers}) => ({default: PurchaseNumbers})));
const PurchasedPhoneNumbers = lazy(() => import("../pages/superadmin/PurchasedPhoneNumbers").then(({PurchasedPhoneNumbers}) => ({default: PurchasedPhoneNumbers})));
const UserList = lazy(() => import("../pages/superadmin/UserList").then(({UserList}) => ({default: UserList})));

export { 
  Dashboard, DashboardPersonalDetails, PersonalStatus, PurchaseNumbers, UserList,
  Login, ForgotPassword, GetOTP, Register, ResetPassword, PurchasedPhoneNumbers
}