import React from "react";

export default function DashboardIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="0.5"
        y="0.5"
        width="7.80039"
        height="7.80039"
        rx="1.5"
        stroke="#42A5E3"
      />
      <rect
        x="11.7007"
        y="0.5"
        width="7.80039"
        height="7.80039"
        rx="1.5"
        stroke="#42A5E3"
      />
      <rect
        x="0.5"
        y="11.6958"
        width="7.80039"
        height="7.80039"
        rx="1.5"
        stroke="#42A5E3"
      />
      <rect
        x="11.7007"
        y="11.6958"
        width="7.80039"
        height="7.80039"
        rx="1.5"
        stroke="#42A5E3"
      />
    </svg>
  );
}
