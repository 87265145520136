import React from "react";

export default function AgentsIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8831 17.5V15.8333C16.8831 14.9493 16.5319 14.1014 15.9068 13.4763C15.2817 12.8512 14.4339 12.5 13.5498 12.5H6.88314C5.99908 12.5 5.15124 12.8512 4.52612 13.4763C3.90099 14.1014 3.5498 14.9493 3.5498 15.8333V17.5"
        stroke="#42A5E3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2171 9.16667C12.0581 9.16667 13.5505 7.67428 13.5505 5.83333C13.5505 3.99238 12.0581 2.5 10.2171 2.5C8.37617 2.5 6.88379 3.99238 6.88379 5.83333C6.88379 7.67428 8.37617 9.16667 10.2171 9.16667Z"
        stroke="#42A5E3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
