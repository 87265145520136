import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Sidebar: React.FC = (): JSX.Element => {

  const navigate = useNavigate();
  const location = useLocation();
  
  const isAgentPage = location?.pathname?.includes("agent");
  const isCallPage = location?.pathname?.includes("call");

  return (
    <>
      <div className="o-dasboard__overlay"></div>
      <div className="o-dasboard__leftbar">
        <ul className="o-dasboard__leftlist">
          <li className="o-dasboard__leftitem">
            <a onClick={() => navigate("/")} className={`o-cusror-pointer o-dasboard__leftitems ${(isAgentPage || isCallPage)?'':'js-active'} `}>
              <span
                className="o-dasboard__itemscenter tooltip"
                data-tooltip="Dashboard"
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="7.80039"
                    height="7.80039"
                    rx="1.5"
                    stroke="#42A5E3"
                  />
                  <rect
                    x="11.7007"
                    y="0.5"
                    width="7.80039"
                    height="7.80039"
                    rx="1.5"
                    stroke="#42A5E3"
                  />
                  <rect
                    x="0.5"
                    y="11.6958"
                    width="7.80039"
                    height="7.80039"
                    rx="1.5"
                    stroke="#42A5E3"
                  />
                  <rect
                    x="11.7007"
                    y="11.6958"
                    width="7.80039"
                    height="7.80039"
                    rx="1.5"
                    stroke="#42A5E3"
                  />
                </svg>
              </span>
              Dashboard
            </a>
          </li>
          <li className="o-dasboard__leftitem ">
            <a
              onClick={()=> navigate("/agent-dashboard")}
              // href="super-agent-dashboard.html"
              className={`o-cusror-pointer o-dasboard__leftitems ${isAgentPage?'js-active':''}`}
            >
              <span
                className="o-dasboard__itemscenter tooltip"
                data-tooltip="Agents"
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.8831 17.5V15.8333C16.8831 14.9493 16.5319 14.1014 15.9068 13.4763C15.2817 12.8512 14.4339 12.5 13.5498 12.5H6.88314C5.99908 12.5 5.15124 12.8512 4.52612 13.4763C3.90099 14.1014 3.5498 14.9493 3.5498 15.8333V17.5"
                    stroke="#42A5E3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.2171 9.16667C12.0581 9.16667 13.5505 7.67428 13.5505 5.83333C13.5505 3.99238 12.0581 2.5 10.2171 2.5C8.37617 2.5 6.88379 3.99238 6.88379 5.83333C6.88379 7.67428 8.37617 9.16667 10.2171 9.16667Z"
                    stroke="#42A5E3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              Agents
            </a>
          </li>
          <li className="o-dasboard__leftitem">
            <a onClick={() => navigate("/call-dashboard")} className={`o-cusror-pointer  o-dasboard__leftitems ${isCallPage?'js-active':''} `}>
              <span
                className="o-dasboard__itemscenter tooltip"
                data-tooltip="Call"
              >
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path
                    d="M13.7501 4.67417C14.6835 4.85629 15.5413 5.31279 16.2138 5.98526C16.8863 6.65774 17.3428 7.51558 17.5249 8.449M13.7501 0.851562C15.6894 1.067 17.4978 1.93544 18.8783 3.31429C20.2589 4.69313 21.1296 6.50043 21.3475 8.43944M20.3919 16.0655V18.9325C20.3929 19.1987 20.3384 19.4621 20.2318 19.706C20.1252 19.9498 19.9688 20.1687 19.7727 20.3486C19.5766 20.5286 19.345 20.6656 19.0929 20.7508C18.8408 20.8361 18.5736 20.8678 18.3085 20.8438C15.3678 20.5243 12.5431 19.5194 10.0613 17.91C7.75224 16.4427 5.7946 14.4851 4.32735 12.176C2.71228 9.68294 1.70719 6.84445 1.3935 3.89054C1.36962 3.62627 1.40102 3.35992 1.48572 3.10845C1.57041 2.85699 1.70654 2.62591 1.88544 2.42994C2.06433 2.23396 2.28207 2.07739 2.52479 1.97017C2.76751 1.86296 3.0299 1.80746 3.29525 1.80721H6.1622C6.62599 1.80265 7.07561 1.96688 7.42726 2.2693C7.77891 2.57172 8.0086 2.99169 8.07351 3.45094C8.19451 4.36843 8.41893 5.26928 8.74246 6.13632C8.87104 6.47837 8.89887 6.85011 8.82265 7.20749C8.74643 7.56487 8.56936 7.89291 8.31242 8.15274L7.09874 9.36642C8.45917 11.7589 10.4401 13.7399 12.8327 15.1003L14.0463 13.8867C14.3062 13.6297 14.6342 13.4526 14.9916 13.3764C15.349 13.3002 15.7207 13.328 16.0628 13.4566C16.9298 13.7802 17.8307 14.0046 18.7481 14.1256C19.2124 14.1911 19.6363 14.4249 19.9394 14.7826C20.2425 15.1403 20.4035 15.5969 20.3919 16.0655Z"
                    stroke="#42A5E3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              Call
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
