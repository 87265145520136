export const routes = {
  
  dashboardPersonalDetails: "/superadmin/dashboard-personal-details",
  businessDetails: "/business-details",
  contactDetails:"/contact-details",
  approcalPending:"/appocal-pending",
  approcalPlans:"/approcal-plans",
  addBalance:"/add-balance",
  dashboard: "/dashboard",
  landingPage: "/",

  agentDashboard: "/agent-dashboard",
  agentLanguage: "/agent-language",
  agentPrompt: "/agent-prompt",

  callDashboard: "/call-dashboard",
  callTranscript: "/call-transcript",

  login: "/customer/login",
  forgotPassword: "/customer/forgot-password",
  getOTP: "/customer/get-otp",
  register: "/customer/register",
  resetPassword: "/superadmin/reset-password"

};
