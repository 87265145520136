export default function ClientBusinessRowItemSkeleton() {
  return (
    <div className="animate-pulse flex gap-4 p-4 border-b border-gray-200">
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      </div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      </div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      </div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
      <div className="flex-1">
        <div className="h-4 bg-gray-200 rounded w-1/3"></div>
      </div>
    </div>
  );
}
