import { Link, useNavigate } from "react-router-dom";
import { useBuyPlanMutation } from "../../hooks/api/useBuyPlanMutation";
import { useState } from "react";
import { useGetCustomerBalanceQuery } from "../../hooks/api/useGetCustomerBalanceQuery";

const AddBalance = () => {
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { data, isPending } = useGetCustomerBalanceQuery();

  const addBalanceMutation = useBuyPlanMutation({
    onSuccess: (data) => {
      console.log({ planResponse: data });
      //   toast.success(data.message);
      navigate("/customer/dashboard/checkout-plan", { state: data.data });
      //   reset();
      //   closeModal();
    },
  });
  const handleAddBalance = async (e) => {
    e.preventDefault();
    if (!balance) {
      setError("Enter a valid amount to proceed!");
      return false;
    }
    addBalanceMutation.mutate({
      amount: Number(balance),
      payment_type: "add_balance",
    });
  };

  return (
    <div className="o-dasboard__rightbar o-dasboard__rightbar--doctordash">
      <Link to="/customer/dashboard" className="o-dasboard__backbtn">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3867 12.2266H5.38672"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3867 19.2266L5.38672 12.2266L12.3867 5.22656"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        BACK
      </Link>
      <div className="o-dasboard__rightbody flex-body flex-div-start">
        <div className="o-general-dashboard__businesstab o-general-dashboard__businesstab--addbalance editformgroup">
          <div className="o-general-dashboard__headtab">
            <h2 className="o-general-dashboard__businesstitle">
              Amount Balance
            </h2>
          </div>
          <p className="o-general-dashboard__showbal">
            Your available balance:{" "}
            <span>${isPending ? "Loading..." : data?.data?.balance}</span>
          </p>
          <form>
            <div className="o-general-dashboard__businessroll">
              <label htmlFor="amountInput">
                Enter amount to add to your balance:
              </label>
              <p className="label-p">Amount</p>
              <div className="o-general-dashboard__dollersign">
                <input
                  type="text"
                  id="amountInput"
                  className="edit-field edit-field--addbalance"
                  placeholder="Enter Amount"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                />
              </div>
              {error && (
                <p className="" style={{ color: "red", fontWeight: "bold" }}>
                  {error}
                </p>
              )}
            </div>
            <button
              className="o-general-dashboard__addbalbtn"
              onClick={handleAddBalance}
            >
              {addBalanceMutation.isPending ? "Loading..." : <>ADD</>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBalance;
