import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config/configs";

const BASE_URL = config.SERVER_BASE_URL;

interface LoginState {
  login_details: any;
  register_details: any;
}

const initialState:LoginState = {
  login_details: {},
  register_details: {},

};

export const loginInfo = createAsyncThunk(
  "login/info",
  async (postData: any, thunkAPI) => {
    console.log("postData...", postData);
    const response = await fetch(BASE_URL + "login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postData)
    });
    const data = response.json();
    return data;
  }
);

export const registerInfo = createAsyncThunk(
  "register/info",
  async (postData: any, thunkAPI) => {
    console.log("postData...", postData);
    const response = await fetch(BASE_URL + "register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postData)
    });
    const data = response.json();
    return data;
  }
);

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    addOrder: (state, action: PayloadAction<{ name: string }>) => {
      // state?.orders?.push({
      //   id: state.orders.length,
      //   name: action.payload.name
      // });
    },

  },
  extraReducers: (builder) => {
    builder.addCase(loginInfo.fulfilled, (state, action) => {
      state.login_details = action.payload;
    });
    builder.addCase(registerInfo.fulfilled, (state, action) => {
      state.register_details = action.payload;
    });
  }

});

export default LoginSlice.reducer;
export const {
  addOrder,
} = LoginSlice.actions;
