import React from "react";

export default function EditIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.813 1.9078C10.98 1.74074 11.1784 1.60822 11.3966 1.51781C11.6149 1.42739 11.8489 1.38086 12.0851 1.38086C12.3214 1.38086 12.5553 1.42739 12.7736 1.51781C12.9919 1.60822 13.1902 1.74074 13.3573 1.9078C13.5243 2.07485 13.6568 2.27318 13.7472 2.49146C13.8377 2.70973 13.8842 2.94367 13.8842 3.17993C13.8842 3.41619 13.8377 3.65013 13.7472 3.86841C13.6568 4.08668 13.5243 4.28501 13.3573 4.45207L4.77034 13.039L1.27197 13.9931L2.22607 10.4947L10.813 1.9078Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
