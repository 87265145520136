import React from 'react';
import './App.css';
import Router from "./routes";

import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { useLocation } from 'react-router-dom';
import SuperAdminRouter from './routes/SuperAdminRouter';

const App : React.FC = (): JSX.Element  =>  {    

  const location = useLocation();
  console.log(location,'location')
  const isCustomerPage = location?.pathname?.includes("customer");
  const isSuperAdminPage = location?.pathname?.includes("superadmin");

  return (
    <div className="bg-dashboard-1">
        <Header />
        <div className="o-main-wrapper overflow-hidden">
             <section className="o-dasboard">
                <div className="o-dasboard__spacing">
                    <div className="o-container">
                        <div className="o-dasboard__container">
                        {isCustomerPage
                            ? <Router /> 
                            :<div className="o-dasboard__flexwrapper">
                                    <div className="o-dasboard__left">
                                        <Sidebar /> 
                                    </div>
                                    <div className="o-dasboard__right">
                                        {
                                            isSuperAdminPage
                                            ? <SuperAdminRouter />
                                            : <Router />
                                        }
                                    </div>
                                </div>
                        }    
                        </div>
                    </div>
                </div>
             </section>
             
        </div>
    </div>
  );
}

export default App;